@page {
    size: auto;   /* auto is the initial value */
    margin: 5mm;  /* this affects the margin in the printer settings */
}
@media print
{
        body,html {
        	font-size: 13px;
        }
        .page-break     { display: block; page-break-before: always; }
}
